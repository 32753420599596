<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex" :rules="rules">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="退货单号" :class="$i18n.locale">
              <el-input v-model="form.returnCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购订单" :class="$i18n.locale">
              <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="稽查报告单编号" :class="$i18n.locale" label-width="120px">
              <el-input v-model="form.auditReportCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单据类型" :class="$i18n.locale">
              <el-select v-model="form.returnType" clearable style="width:100%;" :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_RETURN_TYPE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库退货单" :class="$i18n.locale">
              <el-input v-model="form.wmsReturnCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="退货仓库" :class="$i18n.locale">
              <el-select
                v-model="form.returnWarehouseId"
                clearable
                filterable
                multiple
                style="width:100%;"
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in warehouseOption"
                  :key="item.id"
                  :label="item.warehouseName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" prop="vendorId" :class="$i18n.locale">
              <el-select
                v-model="form.vendorId"
                clearable
                filterable
                multiple
                style="width:100%;"
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" :class="$i18n.locale">
              <el-select v-model="form.returnStatus" clearable multiple style="width:100%;" :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in _getAllCommodityDict('RETURN_GOODS_STATUS')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.supStyle"
                multiple
                value-key="id"
                clearable
                filterable
                style="width:100%;"
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="退货原因" :class="$i18n.locale">
              <el-select v-model="form.returnReason" clearable multiple style="width:100%;" :placeholder="$t('page.selectPlaceholder')">
                <el-option label="仓库稽查NG" value="1" />
                <el-option label="批量性贴标异常" value="2" />
                <el-option label="其他原因" value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="操作人" :class="$i18n.locale">
              <el-select v-model="form.createById" multiple clearable filterable style="width:100%;">
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="value1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>

        </el-row>

        <el-row class="mb-3">
          <el-col :span="24">
            <router-link to="added" append>
              <el-button v-permission="'add'" type="primary">新增</el-button>
            </router-link>
            <el-button type="primary" class="ml-4 mr-4" @click="handleExport">导出</el-button>
            <el-button type="primary" class="mr-4" @click="handleChange">转仓库退货</el-button>
            <el-button v-permission="'approaval'" type="primary" :loading="approavalLoading" @click="handleApproaval">重新发起审批</el-button>

          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" @click="queryClick(0)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <TableSelectAction :quantity="multipleSelection.length" @reset="handleTableSelectReset" />
    <el-table
      ref="tableDatas"
      v-loading="tableDatasLoading"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="600px"
      :summary-method="getSummaries"
      show-summary
      @selection-change="SelectionChange"
    >
      <el-table-column type="selection" width="60" align="center" />
      <el-table-column :label="$t('page.No')" type="index" width="70" align="center" />
      <el-table-column label="退货单号" min-width="190">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: 'detail',
              query: { id: scope.row.id },
              append:true
            }"
            style="color: #1890ff"
          >{{
            scope.row.returnCode
          }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="returnTypeI18" label="单据类型" width="100" />
      <el-table-column prop="purchaseOrderCode" label="采购订单" width="170">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.purchaseOrderCode.split(',')" :key="index">{{ item }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="auditReportCode" label="稽查报告单编号" width="190" />
      <el-table-column prop="wmsReturnCode" label="仓库退货单" width="190" />
      <el-table-column prop="style" label="Style" width="100" />
      <el-table-column prop="returnWarehouseName" label="退货仓库" />
      <el-table-column prop="vendorName" label="供应商" width="90" />
      <el-table-column prop="dealWayI18" label="处理方式" width="90">
        <template slot-scope="{row}">
          <div>{{ row.dealWayI18&&row.dealWayI18!=='null'?row.dealWayI18:'' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="expectReturnPairs" width="110px" label="预期退货数量" />
      <el-table-column prop="expectReturnPrice" width="110px" label="预期退货金额" />
      <el-table-column prop="actualReturnPairs" width="110px" label="实际退货数量" />
      <el-table-column prop="returnCollectNumber" label="返修入库数量" width="120" />
      <el-table-column prop="actualReturnPrice" width="110px" label="实际退货金额" />
      <!-- 退货装货时间、退货立方数、运费承担方 -->
      <!-- <el-table-column prop="" label="退货装货时间" width="120" />
      <el-table-column prop="" label="退货立方数" width="100" />
      <el-table-column prop="" label="运费承担方" width="100" /> -->
      <el-table-column prop="returnStatusI18" label="状态" width="110" />
      <el-table-column prop="createTime" label="操作时间" width="160" />
      <el-table-column prop="createByName" label="操作人" width="100" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.returnStatus+''!=='6' && scope.row.returnStatus+''!=='5'" type="text" @click="cancelPurchaseOrder(scope.row.id)">作废</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="_pagination"
      />
    </div>
  </div>
</template>

<script>
import { returnGoodsOrderExport, queryVendorList, usersList, returnPageList, queryWarehouseList,
  convertToWmsReturnGoods, cancel, queryStyleList, createApproveApi } from '@/api/scm-api'
import Pagination from '@/components/Pagination'
import { omit } from 'lodash'
import { handleDownload } from '@/utils/index'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import TableSelectAction from '@/components/TableSelectAction'
export default {
  components: { Pagination, TableSelectAction },
  mixins: [commodityInfoDict],
  data() {
    return {
      ids: [],
      tableDatasLoading: false,
      approavalLoading: false,
      tableDatas: [],
      multipleSelection: [],
      showRow: true, // 切换显示input
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      vendorOptions: [],
      form: {
        returnWarehouseId: [], vendorId: [], returnStatus: [], returnReason: [], createById: []
      },
      useroptions: [],
      value1: [],
      warehouseOption: [],
      rules: {},
      styleOptions: []
    }
  },
  computed: {
    returnPageListParams() {
      const { returnWarehouseId, vendorId, returnStatus, returnReason, createById } = this.form
      const [startCreateTime, endCreateTime] = this.value1 || []
      return Object.assign({}, this.pager, this.form, { returnWarehouseId: returnWarehouseId.join(), vendorId: vendorId.join(), returnStatus: returnStatus.join(),
        returnReason: returnReason.join(), createById: createById.join() }, { startCreateTime, endCreateTime })
    }
  },
  created() {

  },
  mounted() {
    this._queryWarehouseList()
    this._queryVendorList()
    this._usersList()
    this._queryStyleList()
    this.queryClick(0)
  },
  methods: {
    async handleExport() {
      const obj = omit(this.returnPageListParams, ['current', 'size', 'pages', 'total'])
      const data = await returnGoodsOrderExport(obj)
      handleDownload(data, '采购退货单.xls')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index >= 7 && index <= 10) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    cancelPurchaseOrder(id) {
      this.$confirm(('确定作废此单吗？'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(async() => {
        const { code, msg } = await cancel(id)
        code === 0 && this.$message.success(msg)
        this.queryClick(0)
      })
    },
    // 仓库
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseOption = datas
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.value1 = []
      this.queryClick(0)
    },
    queryClick(type) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.search(type)
        } else {
          this.$message.info('退货原因必填')
        }
      })
    },
    async search(type) {
      try {
        type === 0 ? this.pager.current = 1 : ''
        this.tableDatasLoading = true
        const { datas, code } = await returnPageList(this.returnPageListParams)
        const { pager, records } = datas
        this.pager = pager
        this.tableDatas = records
        code === 0 ? this.tableDatasLoading = false : ''
      } catch (err) {
        console.log(err)
      } finally {
        this.tableDatasLoading = false
      }
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    modifyClick(row) {
      this.$router.push({
        path: 'detail',
        append: 'true',
        query: { 'id': row }
      })
    },
    handleChange() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return
      }
      if (this.multipleSelection.some(item => item.returnStatus !== 2)) {
        this.$message.warning('仅限审批通过状态单操作')
        return
      }
      const arr = this.multipleSelection.map(item => item.returnCode)
      this.$confirm('确定转WMS退货出库单吗！', this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(async() => {
        const { code, msg } = await convertToWmsReturnGoods({ returnCodes: arr.toString() })
        code === 0 && this.$message.success(msg)
        this.queryClick(0)
      })
    },
    SelectionChange(val) {
      this.multipleSelection = val
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      this.$refs.tableDatas.clearSelection()
    },
    showRowClick() {
      this.showRow = !this.showRow
    },
    _pagination(val) {
      const { current, size } = val
      this.pager.current = current
      this.pager.size = size
      this.queryClick(1)
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 重新发起审批
    async handleApproaval() {
      if (!this.multipleSelection.length || this.multipleSelection.length !== 1) return this.$message.warning('只能选择一条数据')
      if (this.multipleSelection.some(item => item.returnStatus !== 3)) return this.$message.warning('仅限审批拒绝状态单操作')
      try {
        this.approavalLoading = true
        const { code } = await createApproveApi(this.multipleSelection[0].id ?? 0)
        code === 0 && this.$message.success('操作成功')
        this.queryClick(1)
      } finally {
        this.approavalLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-range-input {
  width: 90px !important;
}
/deep/.el-date-editor {
  width: 100%;
}
</style>
